import React from "react";
import PageScrollTop from "../components/pageToTop/PageScrollTop";

import "../scss/style.scss";

export default function App({
  Component,
  pageProps,
}: {
  Component: any;
  pageProps: any;
}) {
  return (
    <PageScrollTop>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Component {...pageProps} />
    </PageScrollTop>
  );
}
